body {
    font-family: sans-serif;
}

table {
    border-collapse: collapse;
    width : 100%;
}


th {
    background-color: #CCCCCC;

}

th,
td {
    padding: 0.5rem;
    border: 1px solid #DFDFDF;
    color: black;
    text-align:center ;
}
