@font-face {
    font-family: 'Gmarket Sans';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/GmarketSansLight.woff') format('woff');
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
    font-family: 'Gmarket Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/GmarketSansMedium.woff') format('woff');
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
    font-family: 'Gmarket Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/GmarketSansBold.woff') format('woff');
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}